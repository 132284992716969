<template>
	<section class="background-div" v-bind:class="!isMobile ? 'justify-center' : 'justify-top'">
		<div class="card-container" v-bind:class="!isMobile ? 'card-container-desktop-lg' : ''">
			<div>
				<div>
					<div class="card card-info card-dark container-row-main-s">
						<div class="card-header text-center">
							<h4 class="m-0">Cadastrar conta</h4>
						</div>
						<div class="mt-3">
							<b-form @submit.prevent="sendEmail">
								<div class="container-user-s">
									<div class="head-row-s row-dark">
										<b-form-input
											name="usuario"
											type="text"
											autocomplete="off"
											class="input-text label-conta text-parcela"
											:disabled="loading"
											v-model="usuario.usuario"
											v-validate="{ required: true }"
											:state="validateState('usuario')"
											placeholder="Usuário"
										/>
									</div>
								</div>
								<div class="container-user-s">
									<div class="head-row-s">
										<b-form-input
											name="nome"
											type="text"
											autocomplete="off"
											class="input-text label-conta text-parcela"
											:disabled="loading"
											v-model="usuario.nome"
											v-validate="{ required: true }"
											:state="validateState('nome')"
											placeholder="Nome"
										/>
									</div>
								</div>
								<div class="container-user-s">
									<div class="head-row-s row-dark">
										<b-form-input
											name="email"
											type="text"
											autocomplete="off"
											class="input-text label-conta text-parcela"
											:disabled="loading"
											v-model="usuario.email"
											v-validate="{ required: true }"
											:state="validateState('email')"
											placeholder="E-mail"
										/>
									</div>
								</div>
								<div class="container-user-s">
									<div class="head-row-s">
										<b-form-input
											name="address"
											type="text"
											autocomplete="off"
											class="input-text label-conta text-parcela"
											:disabled="loading"
											v-model="usuario.address"
											v-validate="{ required: false }"
											:state="validateState('address')"
											placeholder="Endereço"
										/>
									</div>
								</div>
								<div class="container-user-s">
									<div class="head-row-s row-dark">
										<b-form-input
											name="identity"
											type="text"
											inputmode="numeric"
											autocomplete="off"
											class="input-text label-conta text-parcela"
											:disabled="loading"
											v-model="usuario.identity"
											v-validate="'validateCPF'"
											:state="validateState('identity')"
											@blur="formatDocument()"
											@input="inputValidate('identity')"
											placeholder="CPF/CNPJ"
										/>
									</div>
								</div>
                                <br>
								<div class="text-center w-100 p-2 mb-3">
									<button
										type="submit"
										class="btn-primary w-100"
										@click="sendCodeValidate()"
										:disabled="invalidForm || sendedCode"
									>
										Cadastrar conta
									</button>
								</div>
								<div
									v-if="sendedCode"
									style="display: flex; align-items: center; justify-content: center; margin-top: -3.7rem; margin-bottom: 1.7rem; color: blue"
								>
									<div class="spinner-border" role="status"/>
								</div>
							</b-form>
						</div>
						<div class="text-center mt-2" style="border-top: 1px solid rgba(0, 0, 0, 0.125); width: 100%; position: relative; top: -12px; padding: 12px 0 6px;">
							<span @click="$router.push({ path: '/'});" style="cursor: pointer; color: white">Fazer login</span>
						</div>
					</div>
					<div class="login-msg" v-if="success || error">
						<span v-if="success" class="success-msg">
							{{ info }}
						</span>
						<span v-else-if="error" class="error-msg">
							{{ error }}
						</span>
					</div>
					<div v-else div style="height: 2rem;"/>
				</div>
			</div>
		</div>
		<b-modal
			ref="validateCode"
			hide-footer
			:hide-header="true"
			size="sm"
			no-overflow
			hide-no-focus
			no-close-on-backdrop
			no-close-on-esc
			centered
		>
			<div class="mb-4" style="font-size: 1.25rem; font-weight: 600; text-align: center;">
				Codigo de Validação
			</div>
			<div style="text-align: center; margin-bottom: 5px; margin-top: 2rem">
				<span>
					Digite o código recebido em seu e-mail!
				</span>
			</div>
			<div>
				<div style="display: flex; justify-content: center">
					<b-form-input
						style="width: 70%; text-align: center; font-size: 1.50rem; font-weight: 800;"
						name="code"
						type="number"
						autocomplete="off"
						class="no-spinner"
						:disabled="false"
						v-model="validateCodeRes"
						v-validate="{ required: true }"
						inputmode="numeric"
						@input="changeCode()"
					/>
				</div>
				<br>
				<div style="display: flex; justify-content: center" class="mt-3 mb-2">
					<button
						@click="validateCodeAndSave()"
						class="btn-primary w-100"
						:disabled="invalidCode || sendedCodeValidate"
					>
						Enviar
					</button>
				</div>
				<div
					v-if="sendedCodeValidate"
					style="display: flex; align-items: center; justify-content: center; margin-top: -2.6rem; margin-bottom: 0.6rem; color: blue"
				>
					<div class="spinner-border" role="status"/>
				</div>
			</div>
		</b-modal>
	</section>
</template>

<script>
	import { UsersService } from "../services/users";
	import { hasFieldsWithErrors } from "@/helpers/validators";
	import { formatIdentityDocument } from "@/helpers/validateIndentity";
	import { validateNumber } from "@/helpers/common";
	import { MessageService } from "@/services/message";
	import axios from "axios";
	import environment from "@/environment";

	export default {
		inject: ["parentValidator"],

		data () {
			return {
				ordensProducao: [],
				selectMaquinas: [],
				usuario: {
					id: null,
					usuario: null,
					nome: null,
					email: null,
					senha: null,
					address: null,
					identity: null
				},
				loading: false,
				invalidForm: true,
				usersService: new UsersService(),
				messageService:new MessageService(),
				errMsg: "",
				validateCodeRes: null,
				sendedCode: false,
				invalidCode: true,
				isMobile: false,
				error: "",
				info: "",
				success: false,
				sendedCodeValidate: false
			};
		},

		watch: {
		},

		beforeDestroy () {
			window.removeEventListener("resize", this.checkIfMobile);
		},

		mounted () {
			this.checkIfMobile();
			window.addEventListener("resize", this.checkIfMobile);
		},

		created () {
			this.$validator = this.parentValidator;
		},

		methods: {
			checkIfMobile	() {
				this.isMobile = window.innerWidth <= 600;
			},

			changeCode () {
				this.invalidCode = false;
				if (this.validateCodeRes.length < 6) {
					this.invalidCode = true;
				} else if (this.validateCodeRes.length > 6) {
					const newCode = this.validateCodeRes.substring(0, 6);
					this.validateCodeRes = newCode;
				}
			},

			async sendCodeValidate () {
				this.sendedCode = true;
				const payload = {
					user: this.usuario.usuario,
					name: this.usuario.nome,
					email: this.usuario.email,
					addressEnterprise: this.usuario.address,
					identityEnterprise:	this.usuario.identity
				};

				try {
					this.error = "";
					this.info = "Enviado codigo...";
					this.success = true;
					const respuesta = await axios.post(`${environment.CREATEUSER}/send-code`,
						payload,
						{
							headers: {
								Origin: window.location.origin
							}
						}
					);

					this.$refs.validateCode.show();
				} catch (e) {
					this.sendedCode = false;
					this.success = false;
					this.error = e?.response?.data?.message || "Erro ao enviar o codigo de validação!";
					await this.$swal.fire({
						title: "Cadastro de Usuario",
						text: e?.response?.data?.message ? e.response.data.message : "Erro ao enviar o codigo de validação!",
						type: "error"
					});
					// this.$router.push({ path: "/"});
				}
			},

			async validateCodeAndSave () {
				this.sendedCodeValidate = true;
				const payload = {
					user: this.usuario.usuario,
					name: this.usuario.nome,
					email: this.usuario.email,
					addressEnterprise: this.usuario.address,
					identityEnterprise: this.usuario.identity,
					code: this.validateCodeRes
				};

				try {
					this.error = "";
					this.info = "Enviado dados...";
					this.success = true;
					const respuesta = await axios.post(`${environment.CREATEUSER}/verify-code`,
						payload,
						{
							headers: {
								Origin: window.location.origin
							}
						}
					);

					this.$refs.validateCode.hide();
					await this.$swal.fire({
						title: "Cadastro de Usuario",
						text: "Novo usuario cadastrado com sucesso! Verifique seu e-mail para ver suas informações de login.",
						type: "success"
					});
					this.$router.push({ path: "/"});


				} catch (e) {
					this.error = e?.response?.data?.message || "Erro ao tentar cadastrar novo usuario!";
					await this.$swal.fire({
						title: "Cadastro de Usuario",
						text: e?.response?.data?.message ? `${e.response.data.message}, tente novamente` : "Erro ao tentar cadastrar novo usuario!",
						type: "error"
					});
					this.sendedCodeValidate = false;
					//this.$refs.validateCode.hide();
					this.success = false;
					// this.$router.push({ path: "/"});
				}
			},

			validateState (ref) {
				this.invalidForm = hasFieldsWithErrors(this.veeFields);
				if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated))
					return !this.veeErrors.has(ref);

				return null;
			},

			changeToggle (status) {
				this.usuario.secure = !status;
			},

			formatDocument () {
				this.usuario.identity = formatIdentityDocument(this.usuario.identity);
			},

			async inputValidate (key) {
				this.usuario[key] = await validateNumber(this.usuario[key]);
			}
		}
	};
</script>

<style scoped>
	.login-msg {
		text-align: center;
		padding-top: 0.5rem;
	}

	.error-msg {
		color: red;
	}

	.success-msg {
		color: #008000;
	}

	.container-user-s {
		width: 100%;
		height: 3.5rem;
	}

	.container-row-main-s {
		border-radius: 10px;
		box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.25);
	}

	.head-row-s {
		width: 100%;
		padding: 8px 16px;
	}

	.row-dark {
		width: 100%;
		padding: 8px 16px;
		background-color: #6e7072;
	}

	.text-head-row-s {
		align-items: center;
		/* font-family: "Roboto Condensed Regular";
		font-size: 20px; */
	}

	.input-text {
		align-items: center;
		/* font-family: "Roboto Condensed Regular";
		font-size: 20px; */
	}

	.no-spinner::-webkit-inner-spin-button,
	.no-spinner::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
</style>
